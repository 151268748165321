/* IMPORTS */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://maxst.icons8.com/vue-static/landings/line-awesome/line-awesome/1.3.0/css/line-awesome.min.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* CUSTOM STYLES */

@layer base {
  html {
    @apply scroll-smooth;
  }
  body {
    @apply relative bg-white dark:bg-gray-900 text-gray-600 dark:text-gray-400 font-sans antialiased mx-auto max-w-3xl xl:max-w-5xl p-4 sm:p-6 xl:px-0 xl:py-8 flex flex-col justify-between;
  }
  h1,h2,h3,h4 {
    @apply tracking-tight text-gray-800 dark:text-gray-100;
  }
  h1 {
    @apply text-3xl sm:text-4xl md:text-5xl leading-9 sm:leading-10 md:leading-14 font-bold;
  }
  h2 {
    @apply text-2xl sm:text-3xl md:text-4xl leading-8 sm:leading-9 md:leading-12 font-semibold;
  }
  h3 {
    @apply text-xl sm:text-2xl md:text-3xl leading-7 sm:leading-8 md:leading-10 font-medium;
  }
  h4 {
    @apply text-lg sm:text-xl md:text-2xl leading-6 sm:leading-7 md:leading-8;
  }
  a {
    @apply text-primary-600 hover:text-primary-400;
  }
  p {
    @apply leading-8;
  }
  .text-highlighted {
    @apply font-semibold text-gray-800 dark:text-gray-100;
  }
}

/* THE OVERLAY (BACKGROUND) */

.overlay {
  height: 100%;
  width: 0;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.25s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

/* POSITION THE CONTENT INSIDE THE OVERLAY */

.overlay-content {
  position: relative;
  top: 25%; /* 25% from the top */
}
